<template>
  <div class="content page-box">
    <div class="page-title">
      <h4 class="title">合同变更</h4>
    </div>
    <section class="content-box">
      <el-form
        ref="changeForm"
        :model="changeForm"
        label-width="120px"
        size="small"
      >
        <!-- 合同变更信息 -->
        <div class="detail-card detail-card-nopadding no-bottom-border">
          <header class="card-header hasbtn">
            <TitleBar
              title="合同变更"
              :innerBtnBox="true"
              :hasStatus="hasStatus"
            >
              <div slot="btnBox">
                <button class="btn btn-lucency" @click="goMainCon">
                  查看主合同
                </button>
                <button class="btn btn-lucency" @click="goApproveDetail">
                  审批详情
                </button>
              </div>
            </TitleBar>
          </header>
          <section class="card-content">
            <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item label="合同变更类型:" prop="typeStr">
                  <el-input
                    type="text"
                    size="small"
                    v-model="changeForm.typeStr"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>
        <!-- 框架协议类金额修改 -->
        <amount-modify
          v-if="isShow"
          ref="amountModify"
          :invoiceTypeList="invoiceTypeList"
          :currencyList="currencyList"
          :forTypeList="forTypeList"
          :costDetailList="costDetailList"
          :costShareDepartList="costShareDepartList"
          :dutyPeoList="dutyPeoList"
          :currentUserName="currentUserName"
          :subList="subList"
          :type="'modify'"
          :modifyData="changeForm"
        >
        </amount-modify>
      </el-form>
    </section>

    <footer class="footer">
      <div class="btnBox">
        <!-- <button class="btn btn-lucency" @click="deleteChange">删除</button> -->
        <button class="btn btn-lucency" @click="goMainCon">返回</button>
        <button
          class="btn btn-primary btn-temporary"
          @click="storage"
          :disabled="storageDisabled"
          :class="storageDisabled ? 'storage_disabled' : ''"
        >
          暂存
        </button>
        <button class="btn btn-primary" @click="approval">下一步</button>
      </div>
    </footer>
  </div>
</template>
<script>
import TitleBar from "@/components/common/TitleBar.vue";
import AmountModify from "@/views/amount-modify/Index.vue";
import getCurrentUser from "@/views/mixins/getCurrentUser";
export default {
  name: "changeEdit",
  components: {
    TitleBar,
    AmountModify
  },
  mixins: [getCurrentUser],
  data() {
    return {
      isShow: false,
      id: "", // 信息修改ID
      contractId: "", // 主合同ID
      approveUrl: "", // 审批地址
      // 审批状态
      hasStatus: {
        show: true,
        status: ""
      },
      // 修改表单
      changeForm: {},
      //发票类型列表
      invoiceTypeList: [],
      // 形成方式
      forTypeList: [],
      // 币种列表
      currencyList: [],
      subList: {},
      // 费项责任人列表
      dutyPeoList: [],
      // 费项列表
      costDetailList: [],
      // 费项预算分摊部门列表
      costShareDepartList: [],
      totalFileList: [],
      loading: null, // 全局loading
      //dateTip: false, // 日期选择提示
      storageDisabled: false, // 暂存按钮禁用
      amountModify: {}
    };
  },
  methods: {
    // 获取路径来源
    getUrl() {
      return new Promise(resolve => {
        let id = this.$route.params.id;
        this.id = id;
        resolve(id);
      });
    },
    // 获取变更信息
    getChangeInfo(id) {
      let params = { id };
      this.$request
        .post("/contractModify/getModifyInfo", { params })
        .then(res => {
          if (res.code === "200") {
            let data = res.data;
            //把回传的文件状态赋值为成功
            data.attachFileList.forEach((item, index) => {
              item.status = "success";
              item.uid = "a" + index;
            });
            this.changeForm = data;
            this.changeForm.typeStr = "框架类协议金额修改";
            this.changeForm.contractAmount = data.amount
              ? parseFloat(data.amount).toFixed(2)
              : "";
            this.isShow = true;
            this.contractId = data.contractId;
            this.hasStatus.status = data.auditStatus
          }
          // 关闭全局loading
          if (this.loading) {
            this.loading.close();
          }
        })
        .catch(error => {
          // 关闭全局loading
          if (this.loading) {
            this.loading.close();
          }
          this.$message.error(error.message);
        });
    },
    // 获取费项列表责任人
    getCostDutePeo() {
      this.$request.post("/employee/queryEmployeeAll?scope=").then(res => {
        if (res.code === "200") {
          this.dutyPeoList = res.data;
        }
      });
    },
    // 获取费项预算部门
    getCostDep() {
      this.$request.post("/cost/queryCostItemDept").then(res => {
        if (res.code === "200") {
          this.costShareDepartList = res.data;
        }
      });
    },
    // 获取费项选择列表
    getCostList() {
      this.$request.post("/cost/queryCostItem").then(res => {
        if (res.code === "200") {
          this.costDetailList = res.data;
        }
      });
    },
    // 下一步保存合同
    saveChange(type) {
      let params = { ...this.amountModify };
      var temp = [...this.filterUploadFailsFiles(params.attachFileList)];
      params.attachFileList = temp;
      params.contractId = this.contractId
      params.id = this.id;
      params.type = 'MONEY'

      if (type == "draft") {
        params.auditStatus = this.changeForm.auditStatus;
      } else {
        params.auditStatus = "";
      }

      return new Promise(resolve => {
        this.$request
          .post("/contractAgreement/saveAgreement", { params })
          .then(res => {
            // 关闭全局loading
            if (this.loading) {
              this.loading.close();
            }
            this.storageDisabled = false;
            if (res.code === "200" && res.data) {
              resolve(res.data);
            }
          })
          .catch(error => {
            // 关闭全局loading
            if (this.loading) {
              this.loading.close();
            }
            this.$message.error(error);
          });
      });
    },
    // 下一步(提交)
    approval() {
      const result = this.$refs.amountModify.submitForm();
      this.amountModify = result;
      // result.flag 为true 验证通过，false 不通过, 不通过直接返回
      if (!result.flag) {
        this.$message.warning('请填写必填项！')
        return;
      }
      this.loading = this.$loading({
        fullscreen: true,
        background: "rgba(255, 255, 255, 0.8)",
        text: "正在保存数据..."
      });

      this.saveChange("change")
        .then(res => {
          this.$message({
            type: "success",
            message: "保存成功，即将跳转审批页面",
            duration: 1500,
            onClose: () => {
              var pWin = window.open("_blank");
              pWin.location = res;
              window.opener = null;
              window.open("about:blank", "_top").close();
            }
          });
        })
        .catch(error => {
          // 关闭全局loading
          if (this.loading) {
            this.loading.close();
          }
          this.$message.error(error);
        });
    },
    // 暂存
    storage() {
      const result = this.$refs.amountModify.storageForm()
      this.amountModify = result
      this.storageDisabled = true;
      this.saveChange("draft").then(res => {
        this.$message({
          type: "success",
          message: "暂存成功",
          duration: 1000
        });
      });
    },

    // 删除变更合同
    deleteChange() {
      this.$confirm("是否立即删除此合同，删除后，将无法恢复", "删除文件", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        lockScroll: false
      })
        .then(() => {
          let params = { id: this.id };
          this.$request
            .post("/contractModify/deleteModify", { params })
            .then(res => {
              if (res.code === "200" && res.data == "success") {
                this.$message({
                  type: "success",
                  message: "删除成功",
                  duration: 1000,
                  onClose: () => {
                    // 关闭当前页
                    window.opener = null;
                    window.open("about:blank", "_top").close();
                  }
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 查看主合同
    goMainCon() {
      this.$open({
        name: "conDetail",
        path: `/condetail/${this.contractId}`,
        params: {
          id: this.contractId
        }
      });
    },
    // 查看审批详情
    goApproveDetail() {
      if (this.approveUrl) {
        window.open(this.approveUrl);
      } else {
        window.open(`/nobpm.html`);
      }
    },
    // 通用-获取主体列表
    getSubList() {
      return new Promise(resolve => {
        this.$request.post("/enterprise/queryEnterpriseAll").then(res => {
          if (res.code === "200") {
            this.subList = res.data;
            resolve();
          }
        });
      });
    },
     // 过滤上传失败文件
    filterUploadFailsFiles(fileListFilter) {
      // 处理附件
      var _file = []
      fileListFilter.forEach((item) => {
        let index = _file.findIndex(v => v.uid === item.uid)
        if("success" === item.status && "-1" == index) {
          if(item.partFiles) {
            item.partFiles = ''
          }
          _file = [
            {...item},
            ..._file
          ]
        }
      })
      return _file
    }
  },
  created() {
    // 打开全局loading
    this.loading = this.$loading({
      fullscreen: true,
      background: "rgba(255, 255, 255, 0.8)",
      text: "正在加载数据..."
    });

    this.currencyList = JSON.parse(localStorage.getItem("currencyList"));
    this.invoiceTypeList = JSON.parse(localStorage.getItem("invoiceTypeList"));
    this.forTypeList = JSON.parse(localStorage.getItem("forTypeList"));

    this.getSubList().then(() => {
      this.getUrl().then(res => {
        this.getChangeInfo(res);
      });
    });
    this.getCurrentUser();
    this.getCostDutePeo();
    this.getCostDep();
    this.getCostList();
  }
};
</script>
<style lang="less" scoped>
.option-wrapper {
  ::v-deep .el-select-dropdown__item.selected::after {
    top: 0;
    color: #997236;
  }
  .option-title {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    box-sizing: border-box;
    align-items: center;
    span.option-name {
      display: inline-block;
      width: 336px;
      color: #606266;
      font-size: 14px;
    }
    .tag {
      width: 48px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      background: #f7ebd4;
      border-radius: 2px;
      font-size: 10px;
      &.T02 {
        color: #7aa2f4 !important;
        background: #eef3fd;
      }
      &.T03 {
        color: #997236 !important;
        background: #f7ebd4;
      }
      &.T04 {
        color: #50ac94 !important;
        background: #e0f7f1;
      }
    }
  }
}
.page-box {
  .page-title {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #eaeaea;
    padding: 6px 12px;
    line-height: 36px;
  }
  .detail-card {
    .card-header {
      .statusBox {
        box-sizing: border-box;
        max-width: 70px;
        height: 20px;
        line-height: 14px;
        // margin: 8px 0 0 10px;
        padding: 4px 6px;
        font-size: 12px;
        position: absolute;
        top: 8px;
        left: 105px;

        .statusIcon {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          margin-right: 2px;
          margin-bottom: 1px;
        }
      }
    }
    .hasbtn {
      display: flex;
      justify-content: space-between;
    }
    .card-content {
      .correlate {
        margin-left: -120px;
      }
      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #efd193;
        background: #efd193;

        &::after {
          background-color: #323232;
          border-radius: 50%;
        }
      }
    }

    .describe {
      h4,
      span {
        display: inline-block;
      }
      span {
        color: #999999;
        font-size: 12px;
        margin-left: 6px;
      }
    }
  }
  .footer {
    .storage_disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
.option-wrapper {
  ::v-deep .el-select-dropdown__item.selected::after {
    top: 0;
    color: #997236;
  }
  .option-title {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    box-sizing: border-box;
    align-items: center;
    span.option-name {
      display: inline-block;
      width: 336px;
      color: #606266;
      font-size: 14px;
    }
    .tag {
      width: 48px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      background: #f7ebd4;
      border-radius: 2px;
      font-size: 10px;
      &.T02 {
        color: #7aa2f4 !important;
        background: #eef3fd;
      }
      &.T03 {
        color: #997236 !important;
        background: #f7ebd4;
      }
      &.T04 {
        color: #50ac94 !important;
        background: #e0f7f1;
      }
    }
  }
}

.page-box {
  .list-title {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    border-bottom: 1px solid #eaeaea;
  }
  .detail-card {
    .card-header {
      .addSub-title {
        display: inline-block;
      }
      .sub-icon {
        display: inline-block;
        margin-left: 8px;
        margin-bottom: -2px;
        cursor: pointer;
      }
    }
    .card-content {
      // .el-row{
      //   height: 50px;
      //   .el-col{
      //     height: 50px;
      //   }
      // }
      .money-icon {
        // vertical-align: text-bottom;
        vertical-align: text-top;
        cursor: pointer;
      }
      .sign-col {
        display: flex;

        .select-sign {
          width: 72px !important;
        }

        .select-sign /deep/ .el-input__inner {
          border-right: 0 !important;
          border-radius: 4px 0 0 4px;
          background-color: #f5f5f5;
        }

        .select-sign /deep/ .el-select__caret {
          color: #323232;
          font-size: 12px;
        }

        .amount-vaild {
          flex: 1;

          .agree-amount {
            .amount-input /deep/ .el-input__inner {
              border-left: 0 !important;
              border-radius: 0 4px 4px 0;
            }
          }
        }

        .amount-vaild /deep/ .el-form-item__content {
          margin-left: 0 !important;
        }
      }

      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #efd193;
        background: #efd193;

        &::after {
          background-color: #323232;
          border-radius: 50%;
        }
      }

      .el-radio__label {
        color: #323232;
      }
      /deep/ .el-radio__input.is-checked + .el-radio__label {
        color: #323232;
      }

      .correlate {
        margin-left: -120px;
      }

      .date-tip {
        font-size: 12px;
        position: relative;
        top: -8px;
        color: #808898;
      }

      .toggle {
        position: absolute;
        left: -120px;
      }

      .tip-icon {
        margin: 0 2px;
        cursor: pointer;
      }
      .triangle {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 7px solid rgba(50, 50, 50, 0.95);
        position: absolute;
        bottom: -1px;
        left: -28px;
        z-index: 99;
      }
      .tips-list {
        box-sizing: border-box;
        width: 450px;
        padding: 6px 12px;
        color: #fff;
        background: rgba(50, 50, 50, 0.95);
        border-radius: 4px;
        position: absolute;
        top: 34px;
        left: -70px;
        z-index: 99;
      }
    }
    .describe {
      h4,
      span {
        display: inline-block;
      }
      span {
        color: #999999;
        font-size: 12px;
        margin-left: 6px;
      }
    }
    .body-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      div.body-cards {
        width: 565px;
        border: 1px solid #f0d8a8;
        border-radius: 6px;
        overflow: hidden;
        h2 {
          width: 100%;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #323232;
          background: #fbf3e5;
          padding: 6px 18px;
          box-sizing: border-box;
        }
        ul {
          widows: 100%;
          padding: 0 18px;
          padding-top: 12px;
          box-sizing: border-box;
          li {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #323232;
            margin-bottom: 12px;
            span {
              display: inline-block;
              margin-left: 12px;
              font-weight: 400;
              font-size: 10px !important;
              line-height: 18px;
              color: #7aa2f4;
              background: #eef3fd;
              border-radius: 2px;
              // width: 32px;
              height: 18px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .detail-card-nopadding {
    padding: 18px 0 0;
    ::v-deep .information-limit {
      margin-bottom: 6px;
    }
    ::v-deep .btn {
      font-size: 12px !important;
    }
  }
  .footer {
    .storage_disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
::v-deep .el-form-item__label {
  color: #323232 !important;
}
::v-deep .el-input__inner {
  text-align: left !important;
  border-radius: 2px;
}
</style>
